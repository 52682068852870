.phoneInputContainer {
    margin-bottom: 20px;
   
  }
  
  .label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    
  }
  
  .phoneInputWrapper {
    display: flex;
    align-items: center;
  }
  
  .phoneInput {
    flex: 1;
    margin-right: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .phoneInput img {
   
    height: 70px;
    margin-right: 5px; /* Add some spacing between the input and the flag */
  }
  
  .errorModal {
    color: red;
    font-size: 14px;
    margin-top: 5px;
  }
  